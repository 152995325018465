<template>
    <div class="maxbox">
        <div>
            <div class="top">
                <p>设置</p>
            </div>
            <div class="main">
                <button class="return" @click="goback"><img src="@/assets/images/left-arrow.png" alt=""></button>
                <div class="main-content">
                    <label for="recipientAddress">接收地址：</label>
                    <input type="text" id="recipientAddress" v-model="recipientAddress" placeholder="请输入接收地址" />
                    <label for="transferAmount">金额(USDT)：</label>
                    <input type="text" id="transferAmount" v-model="transferAmount" placeholder="请输入转账金额 (USDT)" />
                    <button id="transferFundsBtn" @click="transferFunds">转账</button>
                    <div id="referrals"></div>
                    <p id="totalReferrals"></p>
                    <button id="getContractBalanceBtn" @click="getContractBalance">查询USDT余额</button>

                    <!-- 新增：MATIC相关操作 -->
                    <label for="maticAmount">MATIC金额：</label>
                    <input type="text" id="maticAmount" v-model="maticAmount" placeholder="请输入MATIC金额" />
                    <button @click="depositMATIC">充值MATIC</button>
                    <button @click="withdrawMATIC">提取MATIC</button>
                    <p>合约MATIC余额：{{ maticBalance }} MATIC</p>

                    <!-- 新增：冻结/解冻账户和查看账户是否被冻结 -->
                    <label for="freezeAddress">账户地址：</label>
                    <input type="text" id="freezeAddress" v-model="freezeAddress" placeholder="请输入账户地址" />
                    <button @click="freezeAccount">冻结账户</button>
                    <button @click="unfreezeAccount">解冻账户</button>
                    <button @click="checkIfFrozen">查看账户是否被冻结</button>
                    <p>账户冻结状态：{{ isFrozen ? "已冻结" : "未冻结" }}</p>

                    <!-- 新增：免费激活会员账户 -->
                    <label for="newUserAddress">新用户地址：</label>
                    <input type="text" id="newUserAddress" v-model="newUserAddress" placeholder="请输入新用户地址" />
                    <label for="referrerAddress">推荐人地址：</label>
                    <input type="text" id="referrerAddress" v-model="referrerAddress" placeholder="请输入推荐人地址" />
                    <label for="membershipType">会员类型：</label>
                    <select id="membershipType" v-model="membershipType">
                        <option value="v1">v1</option>
                        <option value="v2">v2</option>
                        <option value="v3">v3</option>
                    </select>
                    <button @click="activateMembership">激活会员账户</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddress, contractABI } from '@/js/contract.js';

export default {
    name: 'settings',
    data() {
        return {
            recipientAddress: '',
            transferAmount: '',
            maticAmount: '',
            newUserAddress: '', // 新增：新用户地址状态
            referrerAddress: '', // 新增：推荐人地址状态
            membershipType: 'v1', // 新增：会员类型
            userAddress: '', // 新增：被改变推荐关系的用户地址
            newReferrerAddress: '', // 新增：新推荐人地址
            maticBalance: '0.0',
            freezeAddress: '', // 新增：冻结/解冻账户的地址
            isFrozen: false // 新增：账户冻结状态
        };
    },
    methods: {
        goback() {
            this.$router.go(-1);
        },
        async connectWallet() {
            try {
                if (typeof window.ethereum !== 'undefined') {
                    this.provider = new ethers.providers.Web3Provider(window.ethereum);
                    await this.provider.send("eth_requestAccounts", []);
                    this.signer = this.provider.getSigner();
                    this.contract = new ethers.Contract(contractAddress, contractABI, this.signer);
                } else {
                    alert('未检测到以太坊钱包，请安装MetaMask。');
                }
            } catch (error) {
                console.error('连接钱包失败:', error);
                alert('连接钱包失败！');
            }
        },
        async transferFunds() {
            if (!this.recipientAddress || !this.transferAmount) {
                alert('请填写接收地址和转账金额。');
                return;
            }

            try {
                await this.connectWallet();
                const tx = await this.contract.withdraw(this.recipientAddress);
                await tx.wait();
                alert('转账成功！');
            } catch (error) {
                console.error('转账失败:', error);
                alert('转账失败！');
            }
        },
        async getContractBalance() {
            try {
                await this.connectWallet();
                const balance = await this.contract.getContractBalance();
                alert(`合约USDT余额：${ethers.utils.formatUnits(balance, 18)} USDT`);
            } catch (error) {
                console.error('查询合约余额失败:', error);
                alert('查询合约余额失败！');
            }
        },
        async depositMATIC() {
            if (!this.maticAmount) {
                alert('请输入MATIC金额。');
                return;
            }

            try {
                await this.connectWallet();
                const tx = await this.contract.depositMATIC({ value: ethers.utils.parseEther(this.maticAmount) });
                await tx.wait();
                alert('MATIC充值成功！');
                await this.getMATICBalance();
            } catch (error) {
                console.error('MATIC充值失败:', error);
                alert(`MATIC充值失败！错误信息：${error.message}`);
            }
        },
        async getMATICBalance() {
            try {
                await this.connectWallet();
                const balance = await this.contract.getMATICBalance();
                this.maticBalance = ethers.utils.formatEther(balance);
            } catch (error) {
                console.error('查询MATIC余额失败:', error);
                alert(`查询MATIC余额失败！错误信息：${error.message}`);
            }
        },
        async withdrawMATIC() {
            if (!this.maticAmount) {
                alert('请输入要提取的MATIC金额。');
                return;
            }

            try {
                await this.connectWallet();
                const tx = await this.contract.withdrawMATIC(ethers.utils.parseEther(this.maticAmount));
                await tx.wait();
                alert('MATIC提取成功！');
                await this.getMATICBalance();
            } catch (error) {
                console.error('MATIC提取失败:', error);
                alert(`MATIC提取失败！错误信息：${error.message}`);
            }
        },
        async withdrawAll() {
            try {
                await this.connectWallet();
                const tx = await this.contract.withdrawAll();
                await tx.wait();
                alert('所有资金提取成功！');
                await this.getMATICBalance();
            } catch (error) {
                console.error('提取所有资金失败:', error);
                alert(`提取所有资金失败！错误信息：${error.message}`);
            }
        },
        // 修改：激活会员账户，使用membershipType状态来决定会员级别
        async activateMembership() {
            if (!this.newUserAddress || !this.referrerAddress) {
                alert('请填写新用户地址和推荐人地址。');
                return;
            }

            try {
                await this.connectWallet();
                const isV2 = this.membershipType === 'v2';
                const isV3 = this.membershipType === 'v3';
                const tx = await this.contract.freeRegister(this.newUserAddress, this.referrerAddress, isV2, isV3);
                await tx.wait();
                alert('会员账户激活成功！');
            } catch (error) {
                console.error('会员账户激活失败:', error);
                alert(`会员账户激活失败！错误信息：${error.message}`);
            }
        },
        // 新增：修改推荐关系
        async modifyReferrer() {
            if (!this.userAddress || !this.newReferrerAddress) {
                alert('请填写用户地址和新推荐人地址。');
                return;
            }

            try {
                await this.connectWallet();
                const tx = await this.contract.modifyReferrer(this.userAddress, this.newReferrerAddress);
                await tx.wait();
                alert('推荐关系修改成功！');
            } catch (error) {
                console.error('推荐关系修改失败:', error);
                alert(`推荐关系修改失败！错误信息：${error.message}`);
            }
        },
        // 新增：冻结账户
        async freezeAccount() {
            if (!this.freezeAddress) {
                alert('请填写要冻结的账户地址。');
                return;
            }

            try {
                await this.connectWallet();
                const tx = await this.contract.freezeAccount(this.freezeAddress);
                await tx.wait();
                alert('账户冻结成功！');
            } catch (error) {
                console.error('冻结账户失败:', error);
                alert(`冻结账户失败！错误信息：${error.message}`);
            }
        },
        // 新增：解冻账户
        async unfreezeAccount() {
            if (!this.freezeAddress) {
                alert('请填写要解冻的账户地址。');
                return;
            }

            try {
                await this.connectWallet();
                const tx = await this.contract.unfreezeAccount(this.freezeAddress);
                await tx.wait();
                alert('账户解冻成功！');
            } catch (error) {
                console.error('解冻账户失败:', error);
                alert(`解冻账户失败！错误信息：${error.message}`);
            }
        },
        // 新增：查看账户是否被冻结
        async checkIfFrozen() {
            if (!this.freezeAddress) {
                alert('请填写要查看的账户地址。');
                return;
            }

            try {
                await this.connectWallet();
                this.isFrozen = await this.contract.isAccountFrozen(this.freezeAddress);
            } catch (error) {
                console.error('查询账户冻结状态失败:', error);
                alert(`查询账户冻结状态失败！错误信息：${error.message}`);
            }
        }
    },
    mounted() {
        this.getMATICBalance();
    }
}
</script>

<style scoped>
@import "@/assets/css/settings.css";
@import "@/assets/css/style.css";

.main {
    padding-top: 50px;
}
</style>