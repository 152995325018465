// import Vue from 'vue'
// import App from './App.vue'
// import router from "@/router"
// Vue.config.productionTip = false

// new Vue({
//   router,
//   render: h => h(App),
// }).$mount('#app')


import Vue from 'vue';
import App from './App.vue';
import router from "@/router";

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  mounted() {
   
    document.documentElement.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    }, false);

    
    var lastTouchEnd = 0;
    document.documentElement.addEventListener('touchend', function(event) {
      var now = Date.now();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    }, false);
  },
}).$mount('#app');



