<template>
  <div id="app">
    <router-view></router-view>
    <div class="footer" >
                    <router-link to="/home" >
                        <img src="@/assets/images/f1-1.png" alt="">
                        <p>首页</p>
                    </router-link>
                    <router-link to="/item" >
                        <img src="@/assets/images/f2-1.png" alt="">
                        <p>项目</p>
                    </router-link>
                    <router-link to="/user" >
                        <img src="@/assets/images/f3-1.png" alt="">
                        <p>个人</p>
                    </router-link>
                </div>
  </div>
</template>

<script>


export default {
  name: 'App',
  
}
</script>

<style scoped>

.footer{
  width:100%;
}
</style>
